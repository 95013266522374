<template>
    <div class="d-flex flex-wrap">
        <div
            v-for="(link, index) in links"
            :key="index"
            class="col-lg-4 col-12 col-md-6 my-2"
        >
            <router-link
                class="btn h-min-44 border-2 d-flex flex-column"
                :to="link.url"
                :class="index % 2 ? 'btn-info' : 'btn-primary'"
            >
                <div class="d-flex justify-content-between">
                    <span class="h4 text-left">{{ link.name }}</span>
                    <span> {{ link.icon }}</span>
                </div>
                <div class="text-justify flex-fill">
                    <span>
                        {{ link.description }}
                    </span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardLinks",
    props: {
        links: { type: Array, default: () => [], required: true }
    }
};
</script>
