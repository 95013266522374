<template>
    <div class="flex flex-column">
        <div class="d-flex justify-content-between">
            <h1 class="text-success text-capitalize">
                Hola {{ user.name }} 🖐
            </h1>
            <!-- <vs-button icon circle @click="openModalEditDefault">🖊</vs-button> -->
        </div>

        <p class="text-justify">
            En este sitio, encontrarás todas las herramientas necesarias para
            gestionar tu negocio. Puedes acceder a todas las funciones
            disponibles a través del menú lateral. A continuación, se presentan
            algunas funciones de uso frecuente.
            <!-- Puedes editar las funciones de uso frecuente haciendo
            click en el lápiz. -->
        </p>

        <div class="col-11 col-lg-6">
            <vs-input
                id="search-items"
                v-model="keySearch"
                border
                type="text"
                placeholder="Que estas buscando ..."
                class="w-100"
                @input="onInput"
            >
                <template #icon> 🔎 </template>
            </vs-input>
        </div>

        <card-links class="mt-3 mb-5" :links="links" />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import CardLinks from "@/components/utils/CardLinks.vue";

export default {
    name: "AppIndex",
    components: {
        CardLinks
    },
    data: () => ({
        links: [],
        isOpenModalEditDefault: false,
        keySearch: ""
    }),
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("control", ["cardLinksAvailable", "cardLinksActive"])
    },
    watch: {
        cardLinksActive(value) {
            this.setInput(value);
        }
    },
    async mounted() {
        this.setInput(this.cardLinksActive);
        this.listNotificationsComponent(true);
        this.reloadMerchantScopes();
    },
    methods: {
        ...mapActions("notifications", ["listNotifications"]),
        ...mapActions("auth", ["reloadMerchantScopes"]),
        openModalEditDefault() {
            this.isOpenModalEditDefault = true;
        },
        async listNotificationsComponent(required = false) {
            try {
                await this.listNotifications({ required });
            } catch (error) {
                console.error(error);
            }
        },
        setInput(value) {
            this.links = [...value];
        },
        onInput() {
            if (!this.keySearch) {
                this.links = [...this.cardLinksActive];
                return;
            }
            const lower = this.keySearch.toLowerCase();
            this.links = this.cardLinksAvailable.filter((item) =>
                item.name.toLowerCase().includes(lower)
            );
        }
    }
};
</script>
